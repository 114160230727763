<template>
  <div class="home">
    <div class="d-sm-block d-mb-block d-lg-none" style="overflow-x: hidden">
      <div class="container-main-mobile">
        <div class="price-container-mobile">
          <div class="header-content d-flex flex-row justify-content-right mb-0">
            <p class="price-header-mobile">Поиграть в бадминтон</p>
          </div>
          <div class="tabs-container mt-1">
            <div class="tabs-mobile">
              <input type="radio" name="tabs1" id="tabone1" checked="checked">
              <label for="tabone1">Расписание для взрослых</label>
              <div class="tab-mobile">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" md="2" class="d-flex align-center flex-column justify-center">
                      <p class="tabs-header-1">Разовое посещение</p>
                      <p class="tabs-price mt-3">3 000 ТГ</p>
                      <div class="horizont-price-line mt-4"></div>
                    </v-col>
                    <div class="vertical-line"></div>
                    <v-col cols="12" md="3">
                      <p class="tabs-header">ул. Толе би, 106 а</p>
                      <p class="tabs-time">Понедельник, пятница 19:30-21:00</p>
                      <div class="horizont-price-line-mobile"></div>
                      <div class="tabs-pricing">
                        <p class="tabs-abonement">Абонемент 8 занятий</p>
                        <p class="tabs-price">20 000 тг</p>
                      </div>
                      <div class="horizont-price-line mt-4"></div>
                    </v-col>
                    <div class="vertical-line"></div>
                    <v-col cols="12" md="3">
                      <p class="tabs-header">ул. Жумалиева, 55</p>
                      <p class="tabs-time">Вторник, четверг 19:00-20:30</p>
                      <div class="horizont-price-line-mobile mt-5"></div>
                      <div class="tabs-pricing">
                        <p class="tabs-abonement">Абонемент 8 занятий</p>
                        <p class="tabs-price">20 000 тг</p>
                      </div>
                      <div class="horizont-price-line mt-4"></div>
                    </v-col>
                    <div class="vertical-line"></div>
                    <v-col cols="12" md="4">
                      <p class="tabs-header">мкр. Аксай 1, 10</p>
                      <p class="tabs-time">Вторник, четверг, суббота, воскресенье 19:30-22:00</p>
                      <div class="horizont-price-line-mobile"></div>
                      <div class="tabs-pricing">
                        <p class="tabs-abonement">Абонемент 8 занятий</p>
                        <p class="tabs-price">20 000 тг</p>
                      </div>
                      <div class="tabs-pricing mt-0">
                        <p class="tabs-abonement">Абонемент 16 занятий</p>
                        <p class="tabs-price">32 500 тг</p>
                      </div>
                      <div class="horizont-price-line mt-4"></div>
                    </v-col>
                  </v-row>
                </v-col>
              </div>
              <input type="radio" name="tabs1" id="tabtwo1">
              <label for="tabtwo1">Расписание для детей</label>
              <div class="tab-mobile">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" md="6">
                      <p class="tabs-header">ул. Толе би, 106 а</p>
                      <p class="tabs-time">Понедельник, среда, пятница 11.00-12.30, суббота 11.00-13.00</p>
                      <p class="tabs-time mt-0">Понедельник, среда, пятница 18.00-19.30, суббота 11.00-13.00</p>
                      <div class="horizont-price-line-2-mobile"></div>
                      <div class="tabs-pricing">
                        <p class="tabs-abonement">Разовое посещение</p>
                        <p class="tabs-price">2 500 тг</p>
                      </div>
                      <div class="tabs-pricing mt-0">
                        <p class="tabs-abonement">Абонемент 8 занятий</p>
                        <p class="tabs-price">25 000 тг</p>
                      </div>
                      <div class="horizont-price-line mt-4"></div>
                    </v-col>
                    <v-col cols="12" md="5">
                      <p class="tabs-header">ул. Жумалиева, 55</p>
                      <p class="tabs-time">Вторник, четверг - 19.00-20.30</p>
                      <div class="horizont-price-line-2-mobile mt-6"></div>
                      <div class="tabs-pricing">
                        <p class="tabs-abonement">Разовое посещение</p>
                        <p class="tabs-price">2 500 тг</p>
                      </div>
                      <div class="tabs-pricing mt-0">
                        <p class="tabs-abonement">Абонемент 8 занятий</p>
                        <p class="tabs-price">16 000 тг</p>
                      </div>
                    </v-col>
                    <v-col cols="12" md="1" class="d-flex align-center justify-center">
                      <img src="../assets/icons/volan-tab.svg" class="tab-volan-mobile">
                    </v-col>
                  </v-row>
                </v-col>
              </div>
            </div>
          </div>
          <div class="free-train">ХОТИТЕ ИСПЫТАТЬ МАССУ ПОЛОЖИТЕЛЬНЫХ ЭМОЦИЙ, ТОГДА ЗАПИСЫВАЙТЕСЬ НА БЕСПЛАТНУЮ ПРОБНУЮ
            ТРЕНИРОВКУ</div>
          <button @click=messageWhatsApp class="submit-application">Оставить заявку</button>
        </div>
        <div class="activity-container-mobile">
          <!-- <div class="image-container">
            <v-responsive>
              <img class="activity-women-mobile" src="../assets/img/activity-women.svg">
            </v-responsive>
        </div> -->
          <div class="activity-content-mobile">
            <p class="activity-header">Cфера Деятельности</p>
            <ol class="services-list">
              <li>Групповые и индивидуальные тренировки для взрослых</li>
              <li>Групповые тренировки для детей от 6 лет</li>
              <li>Подготовка и вывоз детей на республиканские соревнования по городам Республики Казахстан</li>
              <li>Организация и проведение детских и взрослых турниров по бадминтону</li>
            </ol>
            <div class="horizontal-line"></div>
            <p class="activity-text" style="    padding-bottom: 147px;">Во время тренировок происходит мощный выплеск
              эмоций, что способствует профилактике
              стрессов, депрессий, малоподвижного образа жизни</p>
            <div class="container-volan-mobile">
              <img class="" src="@/assets/img/volan-test.png">
            </div>
            <div class="circular-mobile">
              <svg viewBox="0 0 100 100">
                <path d="M 0,50 a 50,50 0 1,1 0,1 z" id="circle" />
                <text>
                  <textPath class="text-volan-mobile" href="#circle">
                    спортивный клуб любителей бамбинтона
                  </textPath>
                </text>
              </svg>
            </div>
            <!-- <img src="../assets/img/volan-home-mobile.svg" class="d-flex align-left mt-6"> -->
          </div>
          <div class="image-container-mobile d-flex justify-end">
            <img class="man-and-woman-activity-mobile" src="../assets/img/home-page-12-mobile.png">
          </div>
        </div>
        <div class="why-us-container-mobile d-flex flex-column">
          <div class="header-container">
            <p class="heading-1-mobile">Почему стоит выбрать занятия бадминтоном?</p>
          </div>
          <div class="why-us-bottom-container-mobile mt-6">
            <!-- Первый столбец с тремя параграфами -->
            <v-col cols="12" md="4" class="d-flex align-center flex-column px-0">
              <div class="why-us-column">
                <div class="why-us-block">
                  <p class="why-us-header-1">Позитивный заряд эмоций</p>
                  <p class="why-us-text-1-mobile">Во время тренировок происходит мощный выплеск эмоций, что способствует
                    профилактике стрессов, депрессий, малоподвижного образа жизни</p>
                </div>
                <div class="why-us-block">
                  <p class="why-us-header-2">Нескучная физическая нагрузка</p>
                  <p class="why-us-text-1-mobile">Выполнять одни и те же упражнения или просто бегать может быть
                    утомительно. При занятиях в бадминтон вы увлекаетесь игрой и не замечаете усталости. При этом
                    различные движения: наклоны, пробежки, выпады и прыжки помогают задействовать и укрепить практически
                    все группы мышц</p>
                </div>
                <div class="why-us-block">
                  <p class="why-us-header-1">Тренировка сердца и легких</p>
                  <p class="why-us-text-1-mobile">Учащенное сердцебиение и дыхание во время динамичной игры прекрасно
                    тренируют легкие и сердце</p>
                </div>
              </div>
            </v-col>

            <!-- Второй столбец с изображением -->
            <v-col cols="12" md="4" class="d-flex justify-center">
              <v-responsive>
                <img src="@/assets/img/why-us-woman-mobile.png" alt="Young Woman" class="img-fluid">
              </v-responsive>
            </v-col>

            <!-- Третий столбец с тремя параграфами -->
            <v-col cols="12" md="4" class="d-flex flex-column px-0">
              <div class="why-us-block">
                <p class="why-us-header-1">Развитие координации и тренировка зрения</p>
                <p class="why-us-text-1-mobile">Бадминтон помогает развить координацию, так как вам необходимо быстро
                  перемещаться по площадке и принимать решения, ориентироваться в пространстве, а постоянное слежение за
                  воланом – прекрасная гимнастика для глаз</p>
              </div>
              <div class="why-us-block">
                <p class="why-us-header-2">Помогает снизить вес</p>
                <p class="why-us-text-1-mobile">За час занятий Бадминтоном теряется порядка 500 калорий и одновременно
                  идет тренировка различных групп мышц</p>
              </div>
              <div class="why-us-block">
                <p class="why-us-header-1">Универсальный вид спорта</p>
                <p class="why-us-text-1-mobile">Бадминтоном могут заниматься и мужчины и женщины в разных возрастах</p>
              </div>
            </v-col>
          </div>
        </div>
      </div>
    </div>
    <div class="d-none d-lg-block d-xl-block d-xxl-block">
      <div class="container-main">


        <div class="price-container">
          <div class="header-content d-flex flex-row justify-content-right">
            <p class="price-header">Поиграть в бадминтон</p>
            <div class="container-volan">
              <img class="" src="@/assets/img/volan-test.png">
            </div>
            <div class="circular">
              <svg viewBox="0 0 100 100">
                <path d="M 0,50 a 50,50 0 1,1 0,1 z" id="circle" />
                <text>
                  <textPath class="red-text-volan" href="#circle">
                    спортивный клуб любителей бамбинтона
                  </textPath>
                </text>
              </svg>
            </div>
          </div>

          <div class="tabs-container mt-1">
            <div class="tabs">
              <input type="radio" name="tabs" id="tabone" checked="checked">
              <label for="tabone">Расписание для взрослых</label>
              <div class="tab">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" md="2" class="d-flex align-center flex-column justify-center"
                      style="border-right: 1px solid rgb(199, 119, 0)">
                      <p class="tabs-header-1">Разовое посещение</p>
                      <p class="tabs-price mt-3">3 000 ТГ</p>
                    </v-col>
                    <div class="vertical-line"></div>
                    <v-col cols="12" md="3" style="border-right: 1px solid rgb(199, 119, 0)">
                      <p class="tabs-header">ул. Толе би, 106 а</p>
                      <p class="tabs-time">Понедельник, пятница 19:30-21:00</p>
                      <div class="horizont-price-line"></div>
                      <div class="tabs-pricing">
                        <p class="tabs-abonement" style="max-width: 160px;">Абонемент 8 занятий</p>
                        <p class="tabs-price">20 000 тг</p>
                      </div>
                    </v-col>
                    <div class="vertical-line"></div>
                    <v-col cols="12" md="3" style="border-right: 1px solid rgb(199, 119, 0)">
                      <p class="tabs-header">ул. Жумалиева, 55</p>
                      <p class="tabs-time">Вторник, четверг 19:00-20:30</p>
                      <div class="horizont-price-line mt-5"></div>
                      <div class="tabs-pricing">
                        <p class="tabs-abonement" style="max-width: 160px;">Абонемент 8 занятий</p>
                        <p class="tabs-price">20 000 тг</p>
                      </div>
                    </v-col>
                    <div class="vertical-line"></div>
                    <v-col cols="12" md="4">
                      <p class="tabs-header">мкр. Аксай 1, 10</p>
                      <p class="tabs-time">Вторник, четверг, суббота, воскресенье 19.30-22.00</p>
                      <div class="horizont-price-line"></div>
                      <div class="tabs-pricing">
                        <p class="tabs-abonement">Абонемент 8 занятий</p>
                        <p class="tabs-price">20 000 тг</p>
                      </div>
                      <div class="tabs-pricing mt-0">
                        <p class="tabs-abonement">Абонемент 16 занятий</p>
                        <p class="tabs-price">32 500 тг</p>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </div>
              <input type="radio" name="tabs" id="tabtwo">
              <label for="tabtwo">Расписание для детей</label>
              <div class="tab">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" md="6" style="border-right: 1px solid rgb(199, 119, 0)">
                      <p class="tabs-header">ул. Толе би, 106 а</p>
                      <p class="tabs-time">Понедельник, среда, пятница 11.00-12.30, суббота 11.00-13.00</p>
                      <p class="tabs-time mt-0">Понедельник, среда, пятница 18.00-19.30, суббота 11.00-13.00</p>
                      <div class="horizont-price-line-2"></div>
                      <div class="tabs-pricing">
                        <p class="tabs-abonement">Разовое посещение</p>
                        <p class="tabs-price">2 500 тг</p>
                      </div>
                      <div class="tabs-pricing mt-0">
                        <p class="tabs-abonement">Абонемент 8 занятий</p>
                        <p class="tabs-price">25 000 тг</p>
                      </div>
                    </v-col>
                    <v-col cols="12" md="5">
                      <p class="tabs-header">ул. Жумалиева, 55</p>
                      <p class="tabs-time">Вторник, четверг - 19.00-20.30</p>
                      <div class="horizont-price-line-2 mt-6"></div>
                      <div class="tabs-pricing">
                        <p class="tabs-abonement">Разовое посещение</p>
                        <p class="tabs-price">2 500 тг</p>
                      </div>
                      <div class="tabs-pricing mt-0">
                        <p class="tabs-abonement">Абонемент 8 занятий</p>
                        <p class="tabs-price">16 000 тг</p>
                      </div>
                    </v-col>
                    <v-col cols="12" md="1" class="d-flex align-center justify-center">
                      <img src="../assets/icons/volan-tab.svg">
                    </v-col>
                  </v-row>
                </v-col>
              </div>
            </div>

          </div>
          <div class="free-train">ХОТИТЕ ИСПЫТАТЬ МАССУ ПОЛОЖИТЕЛЬНЫХ ЭМОЦИЙ, ТОГДА ЗАПИСЫВАЙТЕСЬ НА БЕСПЛАТНУЮ
            ПРОБНУЮ
            ТРЕНИРОВКУ
          </div>
          <button @click=messageWhatsApp class="submit-application">Оставить заявку</button>
        </div>
        <div class="activity-container">
          <v-row style="max-width: 1510px;">

            <div class="image-container" ref="imageContainer1">
              <img class="activity-women" src="../assets/img/home-page-12.png">
            </div>
            <div class="activity-content">
              <p class="activity-header">Cфера Деятельности</p>
              <ol class="services-list">
                <li>Групповые и индивидуальные тренировки для взрослых</li>
                <li>Групповые тренировки для детей от 6 лет</li>
                <li>Подготовка и вывоз детей на республиканские соревнования по городам Республики Казахстан</li>
                <li>Организация и проведение детских и взрослых турниров по бадминтону</li>
              </ol>
              <div class="horizontal-line"></div>

              <p class="activity-text">Во время тренировок происходит мощный выплеск эмоций, что способствует
                профилактике
                стрессов, депрессий, малоподвижного образа жизни</p>
            </div>
            <!-- <div id="trigger-element"></div> -->
            <div class="image-container" ref="imageContainer">
              <img class="man-and-woman-activity" src="../assets/img/home-page-13.png"
                alt="Man and Woman Activity">
            </div>
          </v-row>
        </div>
        <div class="why-us-container">
          <div class="header-container">
            <p class="heading-1">Почему стоит выбрать занятия бадминтоном?</p>
          </div>
          <div class="why-us-bottom-container">
            <v-row class="justify-center">
              <!-- Первый столбец с тремя параграфами -->
              <v-col cols="12" md="3" class="d-flex align-center flex-column">
                <div class="why-us-column">
                  <div class="why-us-block">
                    <p class="why-us-header-1">Позитивный заряд эмоций</p>
                    <p class="why-us-text-1">Во время тренировок происходит мощный выплеск эмоций, что способствует
                      профилактике
                      стрессов, депрессий, малоподвижного образа жизни</p>
                  </div>
                  <div class="why-us-block">
                    <p class="why-us-header-2">Нескучная физическая нагрузка</p>
                    <p class="why-us-text-1">Выполнять одни и те же упражнения или просто бегать может быть
                      утомительно.
                      При
                      занятиях в бадминтон вы увлекаетесь игрой и не замечаете усталости. При этом различные движения:
                      наклоны,
                      пробежки, выпады и прыжки помогают задействовать и укрепить практически все группы мышц</p>
                  </div>
                  <div class="why-us-block">
                    <p class="why-us-header-1">Тренировка сердца и легких</p>
                    <p class="why-us-text-1">Учащенное сердцебиение и дыхание во время динамичной игры прекрасно
                      тренируют
                      легкие и сердце</p>
                  </div>
                </div>
              </v-col>


              <!-- Второй столбец с изображением -->
              <v-col cols="12" md="3" class="d-flex justify-center">
                <img src="@/assets/img/young-woman.png" ref="image" class="scroll-animate" alt="Young Woman">
              </v-col>

              <!-- Третий столбец с тремя параграфами -->
              <v-col cols="12" md="3" class="d-flex align-center flex-column">
                <div class="why-us-block">
                  <p class="why-us-header-1">Развитие координации и тренировка зрения</p>
                  <p class="why-us-text-1">Бадминтон помогает развить координацию, так как вам необходимо быстро
                    перемещаться по
                    площадке и принимать решения, ориентироваться в пространстве, а постоянное слежение за воланом –
                    прекрасная
                    гимнастика для глаз</p>
                </div>
                <div class="why-us-block">
                  <p class="why-us-header-2">Помогает снизить вес</p>
                  <p class="why-us-text-1">За час занятий Бадминтоном теряется порядка 500 калорий и одновременно идет
                    тренировка различных групп мышц</p>
                </div>
                <div class="why-us-block">
                  <p class="why-us-header-1">Универсальный вид спорта</p>
                  <p class="why-us-text-1">Бадминтоном могут заниматься и мужчины и женщины в разных возрастах</p>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'

export default {
  name: 'HomeView',
  components: {
    Header
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  mounted() {
    this.startAnimation();
    this.startAnimation2();
    window.addEventListener('scroll', this.handleScroll);


  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);


  },
  methods: {
    messageWhatsApp() {
      const phoneNumber = '77773856887';
      // Заранее написанный текст
      const message = 'Здравствуйте, хотел бы записаться на бесплатную пробную тренировку.';
      // Создание ссылки для WhatsApp
      const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

      // Открытие ссылки в новом окне
      window.open(whatsappLink, '_blank');
    },
    handleScroll() {
      const image = this.$refs.image;
      const rect = image.getBoundingClientRect();
      if (rect.top < window.innerHeight) {
        image.classList.add('visible');
      }
    },
    startAnimation() {
      const imageContainer = this.$refs.imageContainer;
      setTimeout(() => {
        imageContainer.classList.add('visible');
      }, 100); // Небольшая задержка для гарантированной работы анимации
    },
    startAnimation2() {
      const imageContainer1 = this.$refs.imageContainer1;
      setTimeout(() => {
        imageContainer1.classList.add('visible');
      }, 100); // Небольшая задержка для гарантированной работы анимации
    }

  }
}
</script>
<style scoped>
.image-container {
  overflow: hidden;
  /* Для предотвращения появления горизонтальной прокрутки */
}

.activity-women {
  opacity: 0;
  transform: translateX(-100%);
  /* Начальное состояние - за пределами экрана справа */
  transition: opacity 1s, transform 1s;

}

.image-container.visible .activity-women {
  opacity: 1;
  transform: translateX(0);
  /* Конечное состояние - на месте */
}

.man-and-woman-activity {
  opacity: 0;
  transform: translateX(100%);
  /* Начальное состояние - за пределами экрана справа */
  transition: opacity 1s, transform 1s;

}

.image-container.visible .man-and-woman-activity {
  opacity: 1;
  transform: translateX(0);
  /* Конечное состояние - на месте */
}

.scroll-animate {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease-out 0.5s, transform 1s ease-out 0.5s;
}

.scroll-animate.visible {
  opacity: 1;
  transform: translateY(0);
}

.text-volan-mobile {
  fill: #165EB5;
  font-family: Days One;
  font-size: 10.7px;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 0%;
  text-transform: uppercase
}

.container-volan-mobile {
  position: absolute;
  display: grid;
  place-items: center;
  width: 76px;
  height: 76px;
  border-radius: 50%;
  margin-right: 40px;
  left: 15%;
  bottom: -33%;
}

.circular-mobile {
  position: absolute;
  width: 76px;
  height: 76px;
  border-radius: 50%;
  margin-right: 20px;
  left: 15%;
  bottom: -33%;
}

.circular-mobile svg {
  display: block;
  overflow: visible;
  margin: -1rem;
}

.circular-mobile path {
  fill: none;

}

.circular {
  position: absolute;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  margin-right: 20px;
}

.container-volan {
  position: absolute;
  display: grid;
  place-items: center;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  margin-right: 20px;
}

.circular svg {
  display: block;
  overflow: visible;
  margin: -1rem;
}

.circular path {
  fill: none;

}

.red-text-volan {
  fill: rgb(22, 94, 181);
  font-family: Days One;
  font-size: 10.7px;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 0%;
  text-transform: uppercase
}



@keyframes spin {
  from {
    transform: rotate(0turn)
  }

  to {
    transform: rotate(1turn)
  }
}

.circular {
  animation: spin 25s infinite linear;

}

.circular-mobile {
  animation: spin 25s infinite linear;

}

.tab-volan-mobile {
  width: 59px;
}

.tabs-mobile {
  display: flex;
  flex-wrap: wrap;
  max-width: 1278px;
  margin-top: 38px;
}

.tabs-mobile label {
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 0px 56px 0px rgba(0, 0, 0, 0.25);
  background: #FCD301;
  color: #4E4E4E;
  max-width: 1216px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
  order: 1;
  display: block;
  padding: 8px 24px 10px 16px;
  cursor: pointer;
  transition: background ease 0.2s;
  max-width: 154px;
}

.tabs-mobile .tab-mobile {
  box-shadow:
    /* тень снизу */
    -10px 0px 56px -10px rgba(0, 0, 0, 0.25),
    10px 0px 56px -10px rgba(0, 0, 0, 0.25);
  /* тень справа */
  background: rgb(255, 213, 0);
  margin-top: 0px;
  z-index: 99;
  position: relative;
  padding: 15px;
  border-radius: 0 10px 10px 10px;
  max-width: 1278px;
  order: 99;
  flex-grow: 1;
  width: 100%;
  display: none;
}

.tabs-mobile input[type="radio"] {
  display: none;
}

.tabs-mobile input[type="radio"]:checked+label {
  background: rgb(255, 213, 0);
  color: rgb(0, 0, 0);
  box-shadow: 0px -20px 26px -10px rgba(0, 0, 0, 0.15), 10px 0px 56px -30px rgba(0, 0, 0, 0.25);
  border: none;
  z-index: 100;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
  border-bottom: 5px rgb(255, 213, 0);

}

.tabs-mobile input[type="radio"]:checked+label+.tab-mobile {
  display: block;
}

.image-container-mobile {
  width: 104%;
  box-sizing: border-box;
}

.man-and-woman-activity-mobile {
  margin-top: -200px;
}

.heading-1-mobile {
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 300;
  line-height: 44px;
  letter-spacing: 0%;
  text-align: center;
  padding-top: 0px;
}

.activity-container-mobile {
  background: linear-gradient(to bottom, #550F96 70%, #FFD500 30%);
  background-color: #550F96;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.activity-content-mobile {
  width: 100%;
  padding: 25px 17px 37px 17px;
  position: relative;
}

/* @media (min-width: 865px) and (max-width: 1263px) {
  .why-us-bottom-container-mobile {
    display: flex;
    flex-direction: row;
  }
} */
.img-fluid {
  max-width: 100%;
  height: auto;
}

.why-us-text-1-mobile {
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
  padding-bottom: 24px;
}

.why-us-container-mobile {
  justify-content: center;
  background-color: #FFD500;
  padding: 0 17px;
}

.price-header-mobile {
  color: rgb(0, 0, 0);
  font-family: Days One;
  font-size: 30px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0%;
  /* text-align: right; */
  max-width: 250px;
  width: 100%;
  text-transform: uppercase;
}

.price-container-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  background-color: #FFD500;
  padding: 0 17px 0 17px;
  background-image: url('@/assets/img/home-page-12.png');
  /* background-size: cover; */
  /* или contain, в зависимости от нужного результата */
  background-repeat: no-repeat;
  background-position: -347px -152px;
}

.container-main-mobile {
  width: 100%;
  margin: auto;
  position: relative;
}

.content-container-mobile {
  margin-top: 10px;
}

.image-container {
  box-sizing: border-box;
}

.man-and-woman-activity {
  /* width: 100%; */
  max-width: 538px;
  /* margin-top: -210px; */
  position: absolute;
  z-index: 99;
  top: 21%;
  right: 15%
}

.activity-women {
  /* width: 100%; */
  position: absolute;
  max-width: 730px;
  top: 13%;
  left: 8%;
  /* margin-top: -390px; */
}

.header-content {
  width: 100%;
  justify-content: right;
  max-width: 1278px;
  margin-bottom: 40px;
}

.volan-home {
  margin-right: 50px;
  /* position: absolute;
  top: -1%;
  right: 20% */
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  max-width: 1278px;
}

.tabs label {
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 0px 56px 0px rgba(0, 0, 0, 0.25);
  background: #FCD301;
  color: #4E4E4E;
  max-width: 1216px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  order: 1;
  display: block;
  padding: 1rem 2rem;
  cursor: pointer;
  transition: background ease 0.2s;
}

.tabs .tab {
  box-shadow:
    /* тень снизу */
    -10px 0px 56px -10px rgba(0, 0, 0, 0.25),
    10px 0px 56px -10px rgba(0, 0, 0, 0.25);
  /* тень справа */
  background: rgb(255, 213, 0);
  margin-top: 0px;
  z-index: 99;
  position: relative;
  padding: 25px;
  border-radius: 0 10px 10px 10px;
  max-width: 1278px;
  order: 99;
  flex-grow: 1;
  width: 100%;
  display: none;
}

.tabs input[type="radio"] {
  display: none;
}

.tabs input[type="radio"]:checked+label {
  background: rgb(255, 213, 0);
  color: rgb(0, 0, 0);
  box-shadow: 0px -20px 26px -10px rgba(0, 0, 0, 0.15), 10px 0px 56px -30px rgba(0, 0, 0, 0.25);
  border: none;
  z-index: 100;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  border-bottom: 5px rgb(255, 213, 0);

}

.tabs input[type="radio"]:checked+label+.tab {
  display: block;
}

@media (max-width: 45em) {
  /* .tabs .tab,
  .tabs label {
    order: initial;
  }
  .tabs label {
    width: 100%;
    margin-right: 0;
    margin-top: 0.2rem;
  } */
}

::v-deep .v-window-item.custom-tab-item.v-window-item--active {
  max-width: 1278px;
}

.custom-tab.active-tab {
  /* background-color: #FFC000; */
  /* font-size: 1.2em; увеличивает размер текста */
  font-weight: bold;
  /* border-bottom: 2px solid #000000f2; */
  box-shadow: 0px 0px 56px 0px rgba(0, 0, 0, 0.25);
  background: rgb(255, 213, 0);
  /* выделяет нижнюю границу */
}

/* .container-main {
  max-width: 1274px;
  margin: auto;
  position: relative;
  padding: 0 10px 0 10px;
  background-color: #FFD500;
} */
.custom-tabs {
  background-color: transparent;
  /* Основной фон вкладок */
}

.custom-tab {
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
  padding: 10px 20px;
  /* Внутренние отступы */
  cursor: pointer;
  text-transform: none;
  /* Убирает автоматическое преобразование текста */
  transition: all 0.3s ease;
  /* Плавный переход */
}

.submit-application {
  border-radius: 5px;
  color: #FFF;
  background: rgb(85, 15, 150);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px 10px 16px;
  margin-top: 31px;
  margin-bottom: 29px;
  color: rgb(255, 255, 255);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: center;
}

.submit-application:hover {
  color: rgb(255, 255, 255);
  background: #390E61;

}

.free-train {
  color: rgb(85, 15, 150);
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
  max-width: 733px;
  margin-top: 31px
}



.col-12:last-child {
  border-right: none;
}


/* .vertical-line {
  border: 1px solid rgb(199, 119, 0);
  height: 171px;
} */
.tabs-pricing {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 11px;
}

.horizont-price-line-mobile {
  border: 1px solid rgb(199, 119, 0);
  width: 100%;
  min-width: 209px;
  height: 0;
}

.horizont-price-line-2-mobile {
  border: 1px solid rgb(199, 119, 0);
  width: 100%;
  min-width: 203px;
  height: 0;
}

.horizont-price-line {
  border: 1px solid rgb(0, 0, 0);
  width: 100%;
  min-width: 209px;
  height: 0;
}

.horizont-price-line-2 {
  border: 1px solid rgb(0, 0, 0);
  width: 100%;
  height: 0;
}

.tabs-price {
  color: rgb(22, 94, 181);
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0%;
  text-align: left;
  text-wrap: nowrap;
}

.tabs-abonement {
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

.tabs-time {
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
  margin-top: 7px;
  padding-bottom: 5px;
}

.tabs-header {
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0;
  text-align: left;
}

.tabs-header-1 {
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0;
  text-align: center;
}

.custom-tab-item {
  box-shadow: 0px 0px 56px 0px rgba(0, 0, 0, 0.25);
  background: rgb(255, 213, 0);
  margin-top: 0px;
  z-index: 99;
  position: relative;
  padding: 25px;
  border-radius: 0 10px 10px 10px;
  min-width: 1278px;
}

.custom-tab {
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 0px 56px 0px rgba(0, 0, 0, 0.25);
  background: rgb(255, 213, 0);
  color: rgb(0, 0, 0);
  max-width: 1216px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

/* Стили для неактивного таба */
.custom-tab:not(.v-tab--active) {
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 0px 56px 0px rgba(0, 0, 0, 0.25);
  background: rgb(255, 213, 0);
  color: rgb(78, 78, 78) !important;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

::v-deep .v-tabs-slider {
  display: none;
}

::v-deep .v-tabs-items {
  background-color: #FFD500;
  border: 2px solid #dab607
    /* box-shadow: 0px 0px 56px 0px rgba(0, 0, 0, 0.25); */
}

::v-deep .v-tab {
  background-color: #FFD500;
  /* box-shadow: 0px 0px 56px 0px rgba(0, 0, 0, 0.25); */
}

.v-tabs-bar {
  border-bottom: none !important;
}

.custom-tab .v-tab__content {
  padding: 16px;
  box-shadow: 0px 0px 56px 0px rgba(0, 0, 0, 0.25);
  background: #fff;
  border-radius: 10px;
  /* Чтобы текст не прилипал к краям */
}

.price-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  background-color: #FFD500;
}

.price-header {
  color: rgb(0, 0, 0);
  font-family: Days One;
  font-size: 45px;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: 0%;
  text-transform: uppercase;
  /* text-align: left; */
  /* margin-right: 93px; */
  margin: auto;
}

.services-list {
  list-style: decimal inside;
  font-size: 16px;
  padding-left: 0;
  text-align: left;
}

.activity-header {
  color: rgb(255, 255, 255);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  padding-bottom: 25px;
  text-transform: uppercase;
}

.services-list li {
  margin-bottom: 10px;
  color: rgb(255, 255, 255);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

.activity-text {
  color: rgb(255, 255, 255);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

.activity-content {
  max-width: 506px;
  padding: 45px 0 57px 0;
}


.activity-container {
  background-color: #550F96;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.horizontal-line {
  border: 1px solid rgb(255, 255, 255);
  width: 483px;
  height: 0;
  align-items: center;
  margin-top: 55px;
  margin-bottom: 25px;
}

.why-us-container {
  justify-content: center;
  /* margin-top: 30px;
  margin-bottom: 63px; */
  background-color: #FFD500;
}

.heading-1 {
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 300;
  line-height: 44px;
  letter-spacing: 0%;
  text-align: center;
  padding-top: 50px;
}

.why-us-header-1 {
  color: rgb(85, 15, 150);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
  padding-bottom: 11px;
}

.why-us-header-2 {
  color: rgb(22, 94, 181);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  padding-bottom: 11px;
}

.why-us-text-1 {
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
  padding-bottom: 80px;
}

.why-us-bottom-container {
  margin-top: 56px;
}

.why-us-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 1800px) {
  .man-and-woman-activity {
    /* width: 100%; */
    max-width: 538px;
    /* margin-top: -210px; */
    position: absolute;
    z-index: 99;
    top: 21%;
    right: 15%
  }

  .activity-women {
    /* width: 100%; */
    position: absolute;
    max-width: 730px;
    top: 13%;
    left: 1%
      /* margin-top: -390px; */
  }
}

@media screen and (max-width: 1600px) {
  .man-and-woman-activity {
    /* width: 100%; */
    max-width: 538px;
    /* margin-top: -210px; */
    position: absolute;
    z-index: 99;
    top: 21%;
    right: 7%
  }

  .activity-women {
    /* width: 100%; */
    position: absolute;
    max-width: 730px;
    top: 13%;
    left: -5%
      /* margin-top: -390px; */
  }
}
@media screen and (max-width: 1400px) {
  .man-and-woman-activity {
    /* width: 100%; */
    max-width: 538px;
    /* margin-top: -210px; */
    position: absolute;
    z-index: 99;
    top: 21%;
    right: 7%
  }

  .activity-women {
    /* width: 100%; */
    position: absolute;
    max-width: 730px;
    top: 13%;
    left: -15%
      /* margin-top: -390px; */
  }
}


@media (min-width: 1264px) and (max-width: 1328px) {
  .circular {
    position: absolute;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    margin-right: 50px;
  }

  .container-volan {
    position: absolute;
    display: grid;
    place-items: center;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    margin-right: 50px;
  }
}

@media (min-width: 959px) and (max-width: 1263px) {
  .why-us-bottom-container-mobile {
    display: flex;
    flex-direction: row;
  }
}

@media screen and (max-width: 959px) {
  .why-us-bottom-container-mobile {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 400px) {
  .image-container-mobile {
    width: 106%;
    box-sizing: border-box;
  }

  .tabs-abonement {
    max-width: 50%
  }
  .container-volan-mobile {
  left: 15%;
  bottom: -25%;
}

.circular-mobile {
  left: 15%;
  bottom: -25%;
}
}

@media screen and (max-width: 346px) {
  .image-container-mobile {
    width: 106%;
    box-sizing: border-box;
  }
  .header-content {
    margin-bottom: 40px !important;
  }
  .price-container-mobile[data-v-9ea40744] {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    background-color: #FFD500;
    padding: 0 17px 0 17px;
    background-image: url(http://localhost:8080/img/home-page-12.3f3730a7.png);
    /* background-size: cover; */
    background-repeat: no-repeat;
    background-position: -347px -112px;
}

}

@media screen and (max-width: 333px) {
  .tabs-mobile label {
    border-radius: 10px 10px 0 0;
    box-shadow: 0px 0px 56px 0px rgba(0, 0, 0, 0.25);
    background: #FCD301;
    color: #4E4E4E;
    max-width: 1216px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: center;
    order: 1;
    display: block;
    padding: 8px 18px 10px 16px;
    cursor: pointer;
    transition: background ease 0.2s;
    max-width: 134px;
  }

  .tabs-mobile input[type="radio"] {
    display: none;
  }

  .tabs-mobile input[type="radio"]:checked+label {
    background: rgb(255, 213, 0);
    color: rgb(0, 0, 0);
    box-shadow: 0px -20px 26px -10px rgba(0, 0, 0, 0.15), 10px 0px 56px -30px rgba(0, 0, 0, 0.25);
    border: none;
    z-index: 100;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: center;
    border-bottom: 5px rgb(255, 213, 0);

  }

  .image-container-mobile {
    width: 125%;
    box-sizing: border-box;
  }
}
</style>
